.form_css{display: block;
    width: 30%;
    margin: auto;}
	.form_css label{text-align:left;}
input[type="text"],input[type="email"],input[type="number"]{
	width:80%;
	padding:5px 22px;
	margin:10px 0;
	box-sizing:border-box;
	border:2px solid #58b19f;
}
textarea{
	width:80%;
	height:50px;
	padding:15px 22px;
	box-sizing:border-box;
	border:2px solid #58b19f;
	border-radius:2px;
	resize:none;
	
}
input[type="submit"]{
	background-color:#8842d5;
	border:2px solid #ccc;
	color:#fff;
	padding:8px 36px;
	text-decoration:none;
	margin:5px 4px;
	cursor:pointer;
}

